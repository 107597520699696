import { useQuery } from "@apollo/client";
import PreviewMode from "@components/atoms/PreviewMode";
import Header from "@components/molecules/Header";
import Footer from "@components/organisms/Footer";
import TopNavigation from "@components/organisms/TopNavigation";
import Blocks from "@components/sections/Blocks";
import { GetPageContent } from "@graphql/operationTypes/GetPageContent";
import { GET_PAGE_CONTENT } from "@graphql/queries";
import { PREVIEW_CONFIG } from "@setup/apolloSetup";
import React from "react";

import ErrorPage from "../ErrorPage";

import styles from "./Page.module.scss";
import { PageProps } from "./types";

const Page: React.FC<PageProps> = ({ id }) => {
  const { loading, error, data } = useQuery<GetPageContent>(
    GET_PAGE_CONTENT,
    {
      variables: { id, preview: !!PREVIEW_CONFIG }
    }
  );

  if ((error || !data) && !loading) return <ErrorPage />;

  return (
    <div className={styles.mainLayout}>
      <Header />
      <TopNavigation />
      {!!PREVIEW_CONFIG && <PreviewMode />}
      {loading || !data ? (
        <p>Loading...</p>
      ) : (
        <Blocks page={data.page} />
      )}
      <Footer />
    </div>
  );
};

export default Page;
