/* eslint-disable camelcase */
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo
} from "react";
import jwt_decode from "jwt-decode";
import jsrsasign from "jsrsasign";
import useLocalStorage from "use-local-storage";

export const UserContext = createContext<{
  userName: string | null;
  getToken: () => boolean;
}>({ userName: null, getToken: () => false });

export const UserContextProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [userName, setUserName] = useLocalStorage("userName", "");

  const checkURLforToken = (name: string) => {
    const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(
      window.location.href
    );

    if (!results) {
      return 0;
    }

    if (results.length > 0) {
      return results[1];
    }

    return 0;
  };

  const getDisplayName = useCallback(
    (token: string) => {
      // From the token, get the givenname to display
      const decoded = jwt_decode<{ givenname: string }>(token);
      const name = decoded.givenname;

      setUserName(name);
    },
    [setUserName]
  );

  const redirectUser = () => {
    // If token is bad/expired or non-existing, redirect user to SSO
    window.location.replace(
      "https://sso.langland-live.co.uk/private/"
    );
  };

  const validateToken = useCallback((token: string) => {
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    if (!publicKey) return;

    const isValid = jsrsasign.KJUR.jws.JWS.verifyJWT(
      token,
      publicKey,
      {
        alg: ["RS256"],
        iss: ["Langland"],
        gracePeriod: 1 * 60 * 60 * 24,
        verifyAt: jsrsasign.KJUR.jws.IntDate.getNow()
      }
    );

    if (!isValid) {
      localStorage.removeItem("userToken");
      redirectUser();
    }
  }, []);

  const getToken = useCallback(() => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      validateToken(userToken);
      getDisplayName(userToken);
    } else {
      const tkn = checkURLforToken("t");

      if (tkn) {
        validateToken(tkn);
        localStorage.setItem("userToken", tkn.toString());
        getDisplayName(tkn);

        window.history.replaceState({}, document.title, "/");

        return true;
      }

      return false;
    }

    return true;
  }, [validateToken, getDisplayName]);

  const value = useMemo(
    () => ({ getToken, userName }),
    [getToken, userName]
  );

  return (
    <UserContext.Provider {...{ value }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
