import PreviewMode from "@components/atoms/PreviewMode";
import Header from "@components/molecules/Header";
import ArticleList from "@components/organisms/ArticleList";
import Footer from "@components/organisms/Footer";
import TopNavigation from "@components/organisms/TopNavigation";
import { PREVIEW_CONFIG } from "@setup/apolloSetup";

import styles from "./Page.module.scss";

const NewsPage: React.FC = () => (
  <div className={styles.mainLayout}>
    <Header />
    <TopNavigation />
    {!!PREVIEW_CONFIG && <PreviewMode />}
    <ArticleList />
    <Footer />
  </div>
);

export default NewsPage;
