import React from "react";
import Link from "@components/atoms/Link";

import { FooterLinksProps } from "./types";
import styles from "./FooterLinks.module.scss";

const FooterLinks: React.FC<FooterLinksProps> = ({ links }) => (
  <ul className={styles.footerlinks}>
    {links.map(({ link, text, isInternal }) => (
      <li key={link} className={styles.footerlinks__item}>
        <Link
          {...{ isInternal }}
          href={link}
          className={styles.footerlinks__link}
        >
          {text}
        </Link>
      </li>
    ))}
  </ul>
);

export default FooterLinks;
