import React from "react";

import { SocialLinkProps } from "./types";
import styles from "./SocialLink.module.scss";

const SocialLink: React.FC<SocialLinkProps> = ({ icon, link }) => (
  <a
    href={link ?? ""}
    className={styles.sociallink}
    target="_blank"
    rel="noreferrer"
  >
    <img
      src={icon?.url ?? ""}
      alt={icon?.title ?? ""}
      className={styles.sociallink__icon}
    />
  </a>
);

export default SocialLink;
