import Card from "@components/molecules/Card";
import clsx from "clsx";

import styles from "./CardsGrid.module.scss";
import { CardsGridProps } from "./types";

const CardsGrid: React.FC<CardsGridProps> = ({ items }) => {
  const length = items.length < 5 ? items.length : 4;
  const hasSmallItems = items.some((item) => item.isSmall);

  return (
    <div
      className={clsx(styles.cardsgrid, {
        [styles[`cardsgrid__${length}`]]: length,
        [styles.cardsgridSmall]: hasSmallItems
      })}
    >
      {items.map(
        ({
          title,
          description,
          image,
          login,
          isInternal,
          link,
          isSmall,
          backgroundPosition,
          externalVideo,
          localVideo
        }) => (
          <Card
            {...{
              title,
              description,
              image,
              login,
              isInternal,
              link,
              isSmall,
              backgroundPosition,
              externalVideo,
              localVideo
            }}
            cardNumber={length}
            key={link}
          />
        )
      )}
    </div>
  );
};

export default CardsGrid;
