import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import "./index.css";
import { PageThemeContextProvider } from "@contexts/PageThemeContext";
import { client } from "@setup/apolloSetup";
import { UserContextProvider } from "@contexts/UserContext";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <PageThemeContextProvider>
      <ApolloProvider {...{ client }}>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </ApolloProvider>
    </PageThemeContextProvider>
  </React.StrictMode>
);
