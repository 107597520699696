import React from "react";
import { Link } from "react-router-dom";

import styles from "./ErrorPage.module.scss";

const ErrorPage = () => (
  <div className={styles.errorpage}>
    <h1 className={styles.errorpage__title}>404</h1>
    <h2 className={styles.errorpage__subtitle}>
      Something went wrong...
    </h2>
    <Link to="/" className={styles.errorpage__link}>
      Go back to homepage
    </Link>
  </div>
);

export default ErrorPage;
