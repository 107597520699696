import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import styles from "./Navigation.module.scss";
import { NavigationProps } from "./types";

const Navigation: React.FC<NavigationProps> = ({ links }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.navigation}>
      <nav
        className={clsx(
          styles.navigation__nav,
          isMenuOpen && styles.isMenuOpen
        )}
      >
        <ul className={styles.navigation__list}>
          {links.map(({ href, text }) => (
            <React.Fragment key={href}>
              <li className={styles.navigation__item}>
                <Link
                  to={href}
                  className={styles.navigation__link}
                  onClick={(): void =>
                    setIsMenuOpen((prevState) => !prevState)
                  }
                >
                  {text}
                </Link>
              </li>
              <span className={styles.navigation__divider}>/</span>
            </React.Fragment>
          ))}
        </ul>
      </nav>
      <div className={styles.navigation__hamburger}>
        <button
          type="button"
          aria-label="Menu trigger"
          className={clsx(
            styles.navigation__hamburgerButton,
            isMenuOpen && styles.isHamburgerOpen
          )}
          onClick={(): void =>
            setIsMenuOpen((prevState) => !prevState)
          }
        >
          <span className={styles.navigation__hamburgerLine} />
          <span className={styles.navigation__hamburgerLine} />
          <span className={styles.navigation__hamburgerLine} />
        </button>
      </div>
    </div>
  );
};

export default Navigation;
