import UserText from "@components/atoms/UserText";
import IconLinks from "@components/molecules/IconLinks";
import { useQuery } from "@apollo/client";
import { GET_TOP_NAVIGATION } from "@graphql/queries";
import {
  GetTopNavigation,
  GetTopNavigationVariables,
  GetTopNavigation_mainNavigationCollection_items_itemsCollection_items as iconCollectionItem
} from "@graphql/operationTypes/GetTopNavigation";
import ErrorPage from "@components/pages/ErrorPage";
import { PREVIEW_CONFIG } from "@setup/apolloSetup";
import { useUserContext } from "@contexts/UserContext";

import styles from "./TopNavigation.module.scss";

const TopNavigation: React.FC = () => {
  const { loading, error, data } = useQuery<
    GetTopNavigation,
    GetTopNavigationVariables
  >(GET_TOP_NAVIGATION, {
    variables: {
      preview: !!PREVIEW_CONFIG
    }
  });
  const { userName } = useUserContext();

  if (loading) return <p>Loading...</p>;
  if (error || !data) return <ErrorPage />;

  const iconLinks =
    data?.mainNavigationCollection?.items[0]?.itemsCollection
      ?.items || [];

  const filteredIconItems = iconLinks?.filter(
    (item): item is iconCollectionItem =>
      item !== undefined && item !== null
  );

  return (
    <div className={styles.topnavigation}>
      <UserText name={userName ?? ""} />
      <IconLinks items={filteredIconItems ?? []} />
    </div>
  );
};

export default TopNavigation;
