import React, { useMemo } from "react";
import { IconLanglandLogo } from "@assets/svg";
import { Link } from "react-router-dom";
import FooterLinks from "@components/molecules/FooterLinks";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import { GET_FOOTER } from "@graphql/queries";
import {
  GetFooter,
  GetFooterVariables,
  GetFooter_footerCollection_items_footerLinksCollection_items as footerCollectionItem,
  GetFooter_footerCollection_items_socialIconsCollection_items as socialCollectionItem
} from "@graphql/operationTypes/GetFooter";
import { PREVIEW_CONFIG } from "@setup/apolloSetup";
import SocialLinks from "@components/molecules/SocialLinks";

import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  const { loading, error, data } = useQuery<
    GetFooter,
    GetFooterVariables
  >(GET_FOOTER, {
    variables: {
      preview: !!PREVIEW_CONFIG
    }
  });

  const footerItems = data?.footerCollection?.items[0];

  const {
    footerLinksCollection,
    copyrightText,
    socialIconsCollection
  } = footerItems || {};

  const filteredFooterItems = useMemo(
    () =>
      footerLinksCollection?.items.filter(
        (item): item is footerCollectionItem =>
          item !== undefined && item !== null
      ),
    [footerLinksCollection?.items]
  );

  const filteredSocialItems = useMemo(
    () =>
      socialIconsCollection?.items.filter(
        (item): item is socialCollectionItem =>
          item !== undefined && item !== null
      ),
    [socialIconsCollection?.items]
  );

  if (loading) return <p>Loading...</p>;

  return (
    <footer className={clsx(styles.footer, "footer")}>
      {!error && data && (
        <>
          <div className={styles.footer__row}>
            <div className={styles.footer__logowrapper}>
              <Link to="/" className={styles.footer__logo}>
                <IconLanglandLogo />
              </Link>
            </div>
            <FooterLinks links={filteredFooterItems ?? []} />
          </div>
          <div className={styles.footer__row}>
            <div className={styles.footer__socials}>
              <SocialLinks items={filteredSocialItems ?? []} />
              {copyrightText && (
                <p className={styles.footer__copyright}>
                  {copyrightText}
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </footer>
  );
};

export default Footer;
