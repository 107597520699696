import React, { PropsWithChildren } from "react";
import { Link as InternalLink } from "react-router-dom";

import { LinkProps } from "./types";
import styles from "./Link.module.scss";

const Link: React.FC<PropsWithChildren<LinkProps>> = ({
  href,
  children,
  isInternal,
  target = "_blank",
  className
}) => (
  <div className={styles.link}>
    {isInternal ? (
      <InternalLink to={href ?? ""} {...{ className }}>
        {children}
      </InternalLink>
    ) : (
      <a href={href ?? ""} {...{ target, className }}>
        {children}
      </a>
    )}
  </div>
);

export default Link;
