import { PageCollectionContextProps } from "@models/pageCollectionContext";
import { createContext, useContext } from "react";

export const PageCollectionContext = createContext<
  Record<string, PageCollectionContextProps> | undefined
>({});

export const PageCollectionContextProvider =
  PageCollectionContext.Provider;

export const usePageCollectionContext = () =>
  useContext(PageCollectionContext);
