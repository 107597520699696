import React from "react";
import { Link } from "react-router-dom";

import { GroupNameProps } from "./types";
import styles from "./GroupName.module.scss";

const GroupName: React.FC<GroupNameProps> = ({ groupName, href }) => (
  <div className={styles.groupName}>
    <h5 className={styles.groupName__title}>{groupName}</h5>
    {href && (
      <div className={styles.groupName__action}>
        <Link to={href} className={styles.groupName__link}>
          See more
        </Link>
      </div>
    )}
  </div>
);

export default GroupName;
