import React from "react";
import { useParams, useLocation, Navigate } from "react-router-dom";
import ErrorPage from "@components/pages/ErrorPage";
import Page from "@components/pages/Page";
import { usePageCollectionContext } from "@contexts/PageCollectionContext";

import ensureLeadingSlash from "./ensureLeadingSlash";

const PageWrapper: React.FC = () => {
  const pagesCollection = usePageCollectionContext();
  const { pathname } = useLocation();
  const { "*": slug } = useParams();
  const resolvedSlug =
    (slug || "/").split("/").filter(Boolean).join("/") || "/";

  if (pathname !== ensureLeadingSlash(resolvedSlug)) {
    return <Navigate to={resolvedSlug} />;
  }

  if (
    !resolvedSlug ||
    !pagesCollection ||
    !pagesCollection[resolvedSlug]
  )
    return <ErrorPage />;

  return <Page id={pagesCollection[resolvedSlug].id} />;
};

export default PageWrapper;
