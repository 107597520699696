import Badge from "@components/atoms/Badge";
import React, { useState } from "react";
import clsx from "clsx";
import Link from "@components/atoms/Link";
import { IconTimer } from "@assets/svg";
import { convertTime } from "@components/utils/convertTime";

import { CardProps } from "./types";
import styles from "./Card.module.scss";
import VideoModal from "./partials/VideoModal";

const Card: React.FC<CardProps> = ({
  title,
  description,
  image,
  login,
  isInternal,
  link,
  date,
  cardNumber,
  isSmall,
  isTall,
  backgroundPosition,
  externalVideo,
  localVideo
}) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const isVideo = externalVideo || localVideo?.url;
  const { url: localVideoUrl } = localVideo || {};

  const content = (
    <div className={styles.card__wrapper}>
      {login && <Badge text={login} />}
      <div
        className={styles.card__image}
        style={{
          backgroundPositionX: backgroundPosition || "center",
          backgroundImage: `url(${image?.url})`
        }}
      />
      <div className={styles.card__content}>
        <h4 className={styles.card__title}>{title}</h4>
        {description && (
          <p className={styles.card__description}>{description}</p>
        )}
        {date && (
          <div className={styles.card__dateWrapper}>
            <span className={styles.card__dateIcon}>
              <IconTimer />
            </span>
            <p className={styles.card__date}>{convertTime(date)}</p>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={clsx(
          styles.card,
          styles[`card__${cardNumber}`],
          isSmall && styles.cardSmall,
          isTall && styles.cardTall
        )}
      >
        {isVideo ? (
          <button
            type="button"
            className={styles.card__button}
            onClick={() => setIsVideoOpen(true)}
          >
            {content}
          </button>
        ) : (
          <Link
            {...{ isInternal }}
            href={link}
            className={styles.card__link}
          >
            {content}
          </Link>
        )}
      </div>
      {isVideo && (
        <VideoModal
          isOpen={isVideoOpen}
          onClose={() => setIsVideoOpen(false)}
          externalUrl={externalVideo}
          internalUrl={localVideoUrl}
        />
      )}
    </>
  );
};

export default Card;
