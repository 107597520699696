import { IconTimer } from "@assets/svg";
import { BLOCKS, Block, Inline } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link } from "react-router-dom";
import { convertTime } from "@components/utils/convertTime";

import ArticleHero from "../ArticleHero";

import styles from "./SingleArticle.module.scss";
import { SingleArticleProps } from "./types";

const SingleArticle: React.FC<SingleArticleProps> = ({
  articleContent
}) => {
  const {
    title,
    postedDate,
    article: articleRichText,
    image,
    slug
  } = articleContent || {};

  const richTextOptions = {
    renderNode: {
      // eslint-disable-next-line react/no-unstable-nested-components
      [BLOCKS.EMBEDDED_ASSET]: ({ data }: Block | Inline) => {
        const img = articleRichText?.links.assets.block.find(
          (index) => index?.sys.id === data.target.sys.id
        );

        return <img src={img?.url || ""} alt={img?.title || ""} />;
      }
    }
  };

  return (
    <div className={styles.singleArticle}>
      <ArticleHero articleImage={image} activeSlug={slug ?? ""} />
      <div className={styles.singleArticleContent}>
        <h1 className={styles.singleArticleTitle}>{title}</h1>
        <div className={styles.singleArticleTime}>
          <IconTimer />
          <span className={styles.singleArticleDate}>
            {convertTime(postedDate)}
          </span>
        </div>
        <div className={styles.singleArticleContentWysiwyg}>
          {documentToReactComponents(
            articleRichText?.json,
            richTextOptions
          )}
        </div>
      </div>
      <Link
        to="/langland-news"
        className={styles.singleArticleReturn}
      >
        <span className={styles.singleArticleReturnArrow}>◄</span>
        Return to Langland News
      </Link>
    </div>
  );
};

export default SingleArticle;
