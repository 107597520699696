import React from "react";
import SocialLink from "@components/atoms/SocialLink";

import { SocialLinksProps } from "./types";
import styles from "./SocialLinks.module.scss";

const SocialLinks: React.FC<SocialLinksProps> = ({ items }) => (
  <ul className={styles.sociallinks}>
    {items.map(({ link, icon }) => (
      <li key={link} className={styles.sociallinks__item}>
        <SocialLink {...{ link, icon }} />
      </li>
    ))}
  </ul>
);

export default SocialLinks;
