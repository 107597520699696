import React, { FC } from "react";

import { VideoModalProps } from "./types";
import styles from "./VideoModal.module.scss";

const VideoModal: FC<VideoModalProps> = ({
  isOpen,
  onClose,
  externalUrl,
  internalUrl
}) =>
  isOpen ? (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={styles.videoModal}
      onClick={onClose}
      onKeyDown={onClose}
      role="dialog"
    >
      <div className={styles.videoModal__content}>
        <span className={styles.videoModal__loader} />
        {externalUrl && (
          <iframe
            className={styles.videoModal__iframe}
            src={`${externalUrl}?autoplay=1&mute=1`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video"
          />
        )}
        {internalUrl && (
          <video
            controls
            className={styles.videoModal__iframe}
            autoPlay
          >
            <track kind="captions" src="captions.vtt" />
            <source src={internalUrl} type="video/mp4" />
          </video>
        )}
      </div>
      <button
        type="button"
        className={styles.videoModal__button}
        onClick={onClose}
      >
        Close
      </button>
    </div>
  ) : null;

export default VideoModal;
