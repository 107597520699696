import Badge from "@components/atoms/Badge";
import Link from "@components/atoms/Link";
import clsx from "clsx";
import React, { useState } from "react";

import VideoModal from "../Card/partials/VideoModal";

import styles from "./Banner.module.scss";
import { BannerProps } from "./types";

const Banner: React.FC<BannerProps> = ({
  icon,
  title,
  description,
  href,
  isInternal,
  background,
  isTall,
  copyColour,
  login,
  backgroundPosition,
  externalVideo,
  localVideo
}) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const isVideo = externalVideo || localVideo?.url;
  const { url: localVideoUrl } = localVideo || {};

  const content = (
    <>
      {login && <Badge text={login} />}
      <img
        src={background?.url ?? ""}
        alt={background?.title ?? ""}
        className={styles.banner__image}
        aria-hidden="true"
        style={{
          objectPosition: backgroundPosition
            ? `${backgroundPosition} center`
            : "center"
        }}
      />
      <div className={styles.banner__content}>
        <img
          className={styles.banner__icon}
          src={icon?.url ?? ""}
          alt={icon?.title ?? ""}
        />
        <div className={styles.banner__head}>
          <h3 className={styles.banner__title}>{title}</h3>
          <p className={styles.banner__description}>{description}</p>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div
        className={clsx(styles.banner, {
          [styles.bannertall]: isTall,
          [styles.bannerLight]: copyColour
        })}
      >
        {isVideo ? (
          <button
            type="button"
            className={styles.banner__button}
            onClick={() => setIsVideoOpen(true)}
          >
            {content}
          </button>
        ) : (
          <Link
            {...{ href, isInternal }}
            className={styles.banner__link}
          >
            {content}
          </Link>
        )}
      </div>
      {isVideo && (
        <VideoModal
          isOpen={isVideoOpen}
          onClose={() => setIsVideoOpen(false)}
          externalUrl={externalVideo}
          internalUrl={localVideoUrl}
        />
      )}
    </>
  );
};

export default Banner;
