import noop from "@components/utils/noop";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo
} from "react";
import useLocalStorage from "use-local-storage";

export const PageThemeContext = createContext<{
  isDarkMode: boolean;
  changeIsDarkMode: () => void;
}>({ isDarkMode: false, changeIsDarkMode: noop });

export const PageThemeContextProvider: React.FC<
  PropsWithChildren
> = ({ children }) => {
  const defaultDark = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const [isDarkMode, setIsDarkMode] = useLocalStorage(
    "isDarkMode",
    defaultDark
  );

  const changeIsDarkMode = useCallback(() => {
    setIsDarkMode((prev) => !prev);
  }, [setIsDarkMode]);

  const value = useMemo(
    () => ({ isDarkMode, changeIsDarkMode }),
    [isDarkMode, changeIsDarkMode]
  );

  return (
    <PageThemeContext.Provider {...{ value }}>
      {children}
    </PageThemeContext.Provider>
  );
};

export const usePageThemeContext = () => useContext(PageThemeContext);
