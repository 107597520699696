import {
  formatDistanceToNow,
  isWithinInterval,
  subWeeks,
  format
} from "date-fns";

export const convertTime = (date: Date | string): string => {
  const dateIsWithinInterval = isWithinInterval(new Date(date), {
    start: subWeeks(new Date(), 4),
    end: new Date()
  });

  const convertedTime = dateIsWithinInterval
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      })
    : format(new Date(date), "EEEE, MMMM d, yyyy");

  return convertedTime;
};
