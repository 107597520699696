import { gql } from "@apollo/client";

export const GET_PAGES = gql`
  query GetPages($preview: Boolean) {
    pagesCollection(preview: $preview) {
      items {
        pagesCollection {
          items {
            sys {
              id
            }
            pageName
            slug
            description
          }
        }
      }
    }
  }
`;

const CARD_FRAGMENT = gql`
  fragment CardFragment on Card {
    title
    description
    image {
      url
      title
    }
    link
    isLinkInternal
    isSmall
    login
    backgroundPosition
    sys {
      id
    }
    externalVideo
    localVideo {
      url
      fileName
    }
  }
`;

export const GET_PAGE_CONTENT = gql`
  ${CARD_FRAGMENT}
  query GetPageContent($id: String!, $preview: Boolean) {
    page(id: $id, preview: $preview) {
      pageName
      sys {
        id
      }
      blocksCollection {
        items {
          ... on Items {
            sys {
              id
            }
            cardsCollection {
              items {
                ...CardFragment
              }
            }
          }
          ... on GroupName {
            groupTitle
            link
            sys {
              id
            }
          }
          ... on Banner {
            sys {
              id
            }
            title
            description
            isLinkInternal
            isTall
            link
            copyColour
            login
            icon {
              title
              url
            }
            backgroundPosition
            background {
              title
              url
            }
            externalVideo
            localVideo {
              url
              fileName
            }
          }
          ... on BigCardWithSmallCards {
            sys {
              id
            }
            bigCard {
              ...CardFragment
            }
            smallCardsCollection {
              items {
                sys {
                  id
                }
                ... on SmallCards {
                  cardsCollection {
                    items {
                      ...CardFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TOP_NAVIGATION = gql`
  query GetTopNavigation($preview: Boolean) {
    mainNavigationCollection(limit: 1, preview: $preview) {
      items {
        itemsCollection {
          items {
            title
            link
            icon {
              title
              url
            }
            iconDark {
              title
              url
            }
            isInternal
          }
        }
      }
    }
  }
`;

export const GET_FOOTER = gql`
  query GetFooter($preview: Boolean) {
    footerCollection(limit: 1, preview: $preview) {
      items {
        footerLinksCollection {
          items {
            text
            link
            isInternal
          }
        }
        socialIconsCollection(limit: 5) {
          items {
            icon {
              url
              title
            }
            link
          }
        }
        copyrightText
      }
    }
  }
`;

export const GET_ARTICLE_LIST = gql`
  query GetArticleList($preview: Boolean) {
    articlePageCollection(limit: 1, preview: $preview) {
      items {
        articlesCollection {
          items {
            sys {
              id
            }
            title
            slug
            image {
              url
              title
            }
            thumbnail {
              url
              title
            }
            summary
            postedDate
          }
        }
      }
    }
  }
`;

export const GET_ARTICLE_CONTENT = gql`
  query GetArticleContent($slug: String!, $preview: Boolean) {
    articleCollection(
      where: { OR: [{ slug_contains: $slug }] }
      order: [postedDate_DESC]
      limit: 1
      preview: $preview
    ) {
      items {
        slug
        sys {
          id
        }
        title
        image {
          url
          title
        }
        summary
        article {
          json
          links {
            assets {
              block {
                title
                url
                sys {
                  id
                }
              }
            }
          }
        }
        postedBy
        postedDate
      }
    }
  }
`;

export const GET_NEWEST_ARTICLES = gql`
  query GetNewestArticles {
    articleCollection(order: [postedDate_DESC], limit: 5) {
      items {
        slug
        sys {
          id
        }
        title
        thumbnail {
          url
          title
        }
        postedDate
      }
    }
  }
`;
