import React from "react";
import { Link } from "react-router-dom";
import { IconLanglandLogo } from "@assets/svg";
import ThemeTrigger from "@components/atoms/ThemeTrigger";
import { usePageCollectionContext } from "@contexts/PageCollectionContext";
import Navigation from "@components/atoms/Navigation";

import styles from "./Header.module.scss";

const Header: React.FC = () => {
  const items = usePageCollectionContext();

  if (!items) return null;

  const navLinks = Object.entries(items)
    .filter(([key]) => key !== "/")
    .map(([key, value]) => ({
      href: `/${key}`,
      text: value.pageName
    }));

  return (
    <header className={styles.header}>
      <Link to="/" className={styles.header__logo}>
        <IconLanglandLogo />
      </Link>
      <Navigation links={navLinks} />
      <ThemeTrigger />
    </header>
  );
};

export default Header;
