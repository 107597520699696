import React from "react";
import Link from "@components/atoms/Link";
import { usePageThemeContext } from "@contexts/PageThemeContext";

import { IconLinksProps } from "./types";
import styles from "./IconLinks.module.scss";

const IconLinks: React.FC<IconLinksProps> = ({ items }) => {
  const { isDarkMode } = usePageThemeContext();

  return (
    <ul className={styles.iconlinks}>
      {items.map(({ link, title, icon, iconDark, isInternal }) => (
        <li key={link} className={styles.iconlinks__item}>
          <Link
            {...{ isInternal }}
            href={link}
            className={styles.iconlinks__item}
          >
            <div className={styles.iconlinks__icon}>
              {isDarkMode ? (
                <img
                  src={iconDark?.url ?? ""}
                  alt={iconDark?.title ?? ""}
                />
              ) : (
                <img src={icon?.url ?? ""} alt={icon?.title ?? ""} />
              )}
            </div>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default IconLinks;
