import { usePageCollectionContext } from "@contexts/PageCollectionContext";
import React from "react";
import { useLocation } from "react-router-dom";

import { UserTextProps } from "./types";
import styles from "./UserText.module.scss";

const UserText: React.FC<UserTextProps> = ({ name }) => {
  const pages = usePageCollectionContext();
  const { pathname } = useLocation();

  if (!pages) return null;

  const resolvedSlug = pathname || "/";
  const isHomePage = resolvedSlug === "/";
  const { description, pageName } =
    pages[
      isHomePage ? resolvedSlug : resolvedSlug.replace("/", "")
    ] || {};

  return (
    <div className={styles.usertext}>
      <h1 className={styles.usertext__title}>
        {isHomePage ? (
          <span>Hey {name || "Langlander"}</span>
        ) : (
          <span>{pageName || "Langland News"}</span>
        )}
      </h1>
      {description && isHomePage && (
        <p className={styles.usertext__description}>{description}</p>
      )}
    </div>
  );
};

export default UserText;
