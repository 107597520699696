import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from "@apollo/client";
import { GraphQLError } from "graphql";
import unset from "lodash/unset";
import last from "lodash/last";
import take from "lodash/take";
import update from "lodash/update";

const PREVIEW_ACCESS_TOKEN_QUERY_PARAM = "previewaccesstoken";

interface PreviewConfig {
  accessToken: string;
}

export const PREVIEW_CONFIG: PreviewConfig | undefined = (() => {
  const searchParams = new URLSearchParams(window.location.search);
  const accessToken = searchParams.get(
    PREVIEW_ACCESS_TOKEN_QUERY_PARAM
  );

  return !accessToken
    ? undefined
    : {
        accessToken
      };
})();

const CONTENTFUL_UNRESOLVABLE_LINK_GRAPHQL_ERROR =
  "UNRESOLVABLE_LINK";

const removeInvalidReferencesLink = new ApolloLink(
  (operation, forward) =>
    forward(operation).map((response) => {
      if (!response.errors) {
        return response;
      }

      const { data, errors, ...responseRest } = response;
      const { filteredErrors, unresolvedLinkErrors } = errors.reduce<{
        filteredErrors: GraphQLError[];
        unresolvedLinkErrors: GraphQLError[];
      }>(
        (result, error) => {
          const { extensions } = error;

          if (
            extensions.contentful?.code !==
            CONTENTFUL_UNRESOLVABLE_LINK_GRAPHQL_ERROR
          ) {
            return {
              ...result,
              filteredErrors: [...result.filteredErrors, error]
            };
          }

          return {
            ...result,
            unresolvedLinkErrors: [
              ...result.unresolvedLinkErrors,
              error
            ]
          };
        },
        {
          filteredErrors: [],
          unresolvedLinkErrors: []
        }
      );

      const filteredData = unresolvedLinkErrors.reduce(
        (result, { path }) => {
          if (!path) {
            return result;
          }

          const lastPathPart = last(path);

          if (typeof lastPathPart !== "number") {
            unset(result, path);

            return result;
          }

          const pathWithoutLastItem = take(path, path.length - 1);

          update(result || {}, pathWithoutLastItem, (value) => {
            if (!Array.isArray(value)) {
              return value;
            }

            return value.filter((_, index) => index !== lastPathPart);
          });

          return result;
        },
        data
      );

      return {
        ...responseRest,
        ...(filteredErrors.length ? { errors: filteredErrors } : {}),
        data: filteredData
      };
    })
);

const httpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE_ID}/`,
  headers: {
    authorization: `Bearer ${
      PREVIEW_CONFIG?.accessToken ||
      process.env.REACT_APP_ACCESS_TOKEN
    }`
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([removeInvalidReferencesLink, httpLink]),
  cache: new InMemoryCache()
});
