import { useUserContext } from "@contexts/UserContext";
import { useMemo } from "react";
import Routes from "@components/utils/Routes";

const App = () => {
  const { getToken } = useUserContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldRedirect = useMemo(() => !getToken(), []);

  if (shouldRedirect) {
    window.location.replace(
      "https://sso.langland-live.co.uk/private/"
    );

    return null;
  }

  return <Routes />;
};

export default App;
