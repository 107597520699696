import { useQuery } from "@apollo/client";
import Card from "@components/molecules/Card";
import {
  GetArticleList,
  GetArticleListVariables
} from "@graphql/operationTypes/GetArticleList";
import { GET_ARTICLE_LIST } from "@graphql/queries";
import { ArticleCollectionProps } from "@models/articleCollection";
import { PREVIEW_CONFIG } from "@setup/apolloSetup";
import { useMemo } from "react";

import styles from "./ArticleList.module.scss";

const ArticleList: React.FC = () => {
  const {
    loading: articleLoading,
    error: articleError,
    data: articleData
  } = useQuery<GetArticleList, GetArticleListVariables>(
    GET_ARTICLE_LIST,
    {
      variables: {
        preview: !!PREVIEW_CONFIG
      }
    }
  );

  const articles = useMemo(() => {
    const articlesData =
      articleData?.articlePageCollection?.items[0]?.articlesCollection?.items.reduce<
        Record<string, ArticleCollectionProps>
      >((result, item) => {
        if (!item) {
          return result;
        }

        const {
          sys: { id },
          slug,
          ...rest
        } = item;

        if (!slug) {
          return result;
        }

        return {
          ...result,
          [slug]: {
            id,
            slug,
            ...rest
          }
        };
      }, {});

    return articlesData ? Object.values(articlesData) : [];
  }, [articleData?.articlePageCollection?.items]);

  const cardCount = (number: number): number => {
    if (number === 0) return 1;
    if (number === 1 || number === 2) {
      return 2;
    }

    return 3;
  };

  if (articleLoading) return <p>Loading...</p>;
  if (articleError) return <p>Error : {articleError.message}</p>;

  return (
    <div className={styles.articleList}>
      <ul className={styles.articleListItems}>
        {articles?.map(({ title, slug, image, summary }, index) => (
          <li key={slug} className={styles.articleListItem}>
            <Card
              title={title ?? ""}
              description={summary ?? ""}
              link={`/article/${slug}` ?? ""}
              {...{ image }}
              isInternal
              cardNumber={cardCount(index)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleList;
