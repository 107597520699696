/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PAGES } from "@graphql/queries";
import {
  GetPages,
  GetPagesVariables
} from "@graphql/operationTypes/GetPages";
import { PageCollectionContextProvider } from "@contexts/PageCollectionContext";
import PageWrapper from "@components/utils/PageWrapper";
import { PageCollectionContextProps } from "@models/pageCollectionContext";
import { PREVIEW_CONFIG } from "@setup/apolloSetup";
import NewsPage from "@components/pages/NewsPage";
import ArticlePage from "@components/pages/ArticlePage";

const router = createBrowserRouter([
  {
    path: "/langland-news",
    element: <NewsPage />
  },
  {
    path: "/article/:slug",
    element: <ArticlePage />
  },
  {
    path: "*",
    element: <PageWrapper />
  }
]);

const Routes: React.FC = () => {
  const { loading, error, data } = useQuery<
    GetPages,
    GetPagesVariables
  >(GET_PAGES, {
    variables: {
      preview: !!PREVIEW_CONFIG
    }
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  const pagesArray =
    data?.pagesCollection?.items[0]?.pagesCollection?.items.reduce<
      Record<string, PageCollectionContextProps>
    >((result, item) => {
      if (!item) {
        return result;
      }

      const {
        slug,
        sys: { id },
        pageName,
        description
      } = item;

      if (!slug) {
        return result;
      }

      return { ...result, [slug]: { id, pageName, description } };
    }, {});

  return (
    <PageCollectionContextProvider value={pagesArray}>
      <RouterProvider {...{ router }} />
    </PageCollectionContextProvider>
  );
};

export default Routes;
