import { useQuery } from "@apollo/client";
import Card from "@components/molecules/Card";
import ErrorPage from "@components/pages/ErrorPage";
import {
  GetNewestArticles,
  GetNewestArticles_articleCollection_items as newestArticleCollectionItem
} from "@graphql/operationTypes/GetNewestArticles";
import { GET_NEWEST_ARTICLES } from "@graphql/queries";
import React, { useMemo } from "react";

import styles from "./ArticleHero.module.scss";
import { ArticleHeroProps } from "./types";

const ArticleHero: React.FC<ArticleHeroProps> = ({
  articleImage,
  activeSlug
}) => {
  const { loading, error, data } = useQuery<GetNewestArticles>(
    GET_NEWEST_ARTICLES
  );

  const latestArticles = useMemo(
    () =>
      data?.articleCollection?.items.filter(
        (item): item is newestArticleCollectionItem =>
          item !== undefined && item !== null
      ),
    [data?.articleCollection?.items]
  );
  const currentArticleArchived = latestArticles?.find(
    ({ slug }) => slug === activeSlug
  );

  const latestArticlesFiltered = currentArticleArchived
    ? latestArticles?.filter(
        ({ slug }) => slug !== currentArticleArchived?.slug
      )
    : latestArticles?.splice(0, 4);

  if (error && !loading) return <ErrorPage />;

  const { url, title: imageTitle } = articleImage ?? {};

  return (
    <div className={styles.articleHero}>
      <div className={styles.articleHeroImageWrapper}>
        <img
          className={styles.articleHeroImage}
          src={url ?? ""}
          alt={imageTitle ?? ""}
        />
      </div>
      <div className={styles.articleHero__small}>
        {latestArticlesFiltered?.map(
          ({ slug, title, thumbnail, postedDate }) => (
            <Card
              image={thumbnail ?? null}
              title={title ?? ""}
              date={postedDate}
              link={`/article/${slug}`}
              key={slug}
              isSmall
              isInternal
            />
          )
        )}
      </div>
    </div>
  );
};

export default ArticleHero;
