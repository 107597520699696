import React, { useEffect } from "react";
import { IconSun, IconMoon } from "@assets/svg";
import { usePageThemeContext } from "@contexts/PageThemeContext";

import { ThemeState } from "./enums";
import styles from "./ThemeTrigger.module.scss";

const ThemeTrigger: React.FC = () => {
  const { changeIsDarkMode, isDarkMode } = usePageThemeContext();

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add(ThemeState.Dark);
      document.body.classList.remove(ThemeState.Light);
    } else {
      document.body.classList.add(ThemeState.Light);
      document.body.classList.remove(ThemeState.Dark);
    }
  }, [isDarkMode]);

  return (
    <div className={styles.trigger}>
      <button
        type="button"
        aria-label="Theme Trigger"
        className={styles.trigger__button}
        onClick={changeIsDarkMode}
      >
        {isDarkMode ? <IconMoon /> : <IconSun />}
      </button>
    </div>
  );
};

export default ThemeTrigger;
