/* eslint-disable no-underscore-dangle */
import Banner from "@components/molecules/Banner";
import GroupName from "@components/molecules/GroupName";
import CardsGrid from "@components/organisms/CardsGrid";
import MixedCards from "@components/organisms/MixedCards";
import React from "react";
import {
  GetPageContent,
  GetPageContent_page_blocksCollection_items as PageContentItem
} from "@graphql/operationTypes/GetPageContent";

import styles from "./Blocks.module.scss";

interface PageContentItemProps {
  item: PageContentItem | null;
}

const RenderComponent: React.FC<PageContentItemProps> = ({
  item
}) => {
  if (!item) return null;

  switch (item.__typename) {
    case "GroupName":
      return (
        <GroupName
          groupName={item.groupTitle ?? ""}
          href={item.link ?? ""}
        />
      );
    case "Items":
      return <CardsGrid items={item.cardsCollection?.items ?? []} />;
    case "Banner":
      return (
        <Banner
          icon={item?.icon}
          title={item?.title ?? ""}
          description={item?.description ?? ""}
          href={item?.link ?? ""}
          isInternal={item?.isLinkInternal ?? false}
          background={item?.background}
          isTall={item?.isTall ?? false}
          copyColour={item?.copyColour ?? false}
          login={item?.login ?? ""}
          backgroundPosition={item?.backgroundPosition ?? undefined}
          externalVideo={item?.externalVideo ?? undefined}
          localVideo={{
            fileName: item?.localVideo?.fileName ?? "",
            url: item?.localVideo?.url ?? ""
          }}
        />
      );
    case "BigCardWithSmallCards":
      return (
        <MixedCards
          bigCard={item.bigCard}
          smallCards={
            item.smallCardsCollection?.items[0]?.cardsCollection
              ?.items ?? []
          }
        />
      );
    default:
      return null;
  }
};

const Blocks: React.FC<GetPageContent> = ({ page }) => {
  const items = page?.blocksCollection?.items;

  if (!items?.length) return null;

  return (
    <div className={styles.blocks}>
      {items.map((item) => {
        const { id } = item?.sys || {};

        return item ? (
          <RenderComponent key={id} {...{ item }} />
        ) : null;
      })}
    </div>
  );
};

export default Blocks;
