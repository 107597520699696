import Card from "@components/molecules/Card";
import React from "react";

import { MixedCardsProps } from "./types";
import styles from "./MixedCards.module.scss";

const MixedCards: React.FC<MixedCardsProps> = ({
  bigCard,
  smallCards
}) => (
  <div className={styles.mixedCards}>
    <Card {...bigCard} isTall />
    <div className={styles.mixedCards__small}>
      {smallCards.map((props) => (
        <Card {...props} key={props.href} />
      ))}
    </div>
  </div>
);

export default MixedCards;
