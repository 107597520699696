import React from "react";

import { BadgeProps } from "./types";
import styles from "./Badge.module.scss";

const Badge: React.FC<BadgeProps> = ({ text }) => (
  <span className={styles.badge}>{text}</span>
);

export default Badge;
