import { useQuery } from "@apollo/client";
import PreviewMode from "@components/atoms/PreviewMode";
import Header from "@components/molecules/Header";
import Footer from "@components/organisms/Footer";
import TopNavigation from "@components/organisms/TopNavigation";
import { PREVIEW_CONFIG } from "@setup/apolloSetup";
import React from "react";
import { GET_ARTICLE_CONTENT } from "@graphql/queries";
import SingleArticle from "@components/organisms/SingleArticle";
import { useLocation } from "react-router-dom";
import {
  GetArticleContent,
  GetArticleContentVariables
} from "@graphql/operationTypes/GetArticleContent";

import ErrorPage from "../ErrorPage";

import { ArticleProps } from "./types";
import styles from "./ArticlePage.module.scss";

const ArticlePage: React.FC<ArticleProps> = () => {
  const { pathname } = useLocation();
  const slug = pathname.replace("/article/", "");
  const { loading, error, data } = useQuery<
    GetArticleContent,
    GetArticleContentVariables
  >(GET_ARTICLE_CONTENT, {
    variables: { slug, preview: !!PREVIEW_CONFIG }
  });

  if ((error || !data) && !loading) return <ErrorPage />;

  return (
    <div className={styles.mainLayout}>
      <Header />
      <TopNavigation />
      {!!PREVIEW_CONFIG && <PreviewMode />}
      {loading || !data ? (
        <p>Loading...</p>
      ) : (
        <SingleArticle
          articleContent={data.articleCollection?.items[0] ?? null}
        />
      )}
      <Footer />
    </div>
  );
};

export default ArticlePage;
